import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Lightbulb } from "lucide-react";
import { formatInsightAmount } from "../lib/utils";

export const OptimizationSuggestionsPanel = ({ suggestions }) => {
  if (!suggestions?.length) return null;

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Lightbulb className="h-5 w-5 text-yellow-500" />
          Smart Optimizations
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {suggestions.map((suggestion, index) => (
            <div key={index} className="border-b pb-4 last:border-0">
              <div className="flex justify-between items-start">
                <h4 className="font-medium">
                  {suggestion.type
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </h4>
                <div className="text-right">
                  <div className="text-sm text-gray-500">
                    Current Spend: {formatInsightAmount(suggestion.currentCost)}
                  </div>
                  <div className="text-sm text-green-600">
                    Potential Savings:{" "}
                    {formatInsightAmount(suggestion.potentialSavings)}
                  </div>
                </div>
              </div>
              <p className="text-sm text-gray-600 mt-1">
                {suggestion.description}
              </p>
              {suggestion.implementationSteps?.length > 0 && (
                <div className="mt-2">
                  <h5 className="text-sm font-medium">Implementation Steps:</h5>
                  <ol className="mt-1 space-y-1 list-decimal list-inside">
                    {suggestion.implementationSteps.map((step, idx) => (
                      <li key={idx} className="text-sm text-gray-600">
                        {step}
                      </li>
                    ))}
                  </ol>
                </div>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
