import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { PiggyBank, TrendingUp } from "lucide-react";
import { formatInsightAmount } from "../lib/utils";

export const SavingsInsightsPanel = ({ insights }) => {
  if (!insights?.length) return null;

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <PiggyBank className="h-5 w-5 text-green-500" />
          Savings Opportunities
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {insights.map((insight, index) => (
            <div key={index} className="border-b pb-4 last:border-0">
              <div className="flex justify-between items-start">
                <h4 className="font-medium">
                  {insight.type
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </h4>
                <span className="text-green-600 font-medium">
                  Goal: {formatInsightAmount(insight.goal)}
                </span>
              </div>
              <p className="text-sm text-gray-600 mt-1">
                {insight.description}
              </p>
              <p className="text-sm text-gray-500 mt-1">
                Timeframe: {insight.timeframe}
              </p>
              {insight.steps?.length > 0 && (
                <div className="mt-2">
                  <h5 className="text-sm font-medium">Action Steps:</h5>
                  <ol className="mt-1 space-y-1 list-decimal list-inside">
                    {insight.steps.map((step, idx) => (
                      <li key={idx} className="text-sm text-gray-600">
                        {step}
                      </li>
                    ))}
                  </ol>
                </div>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
