import { gql } from "@apollo/client";

export const GENERATE_INSIGHTS = gql`
  mutation GenerateFinancialInsights {
    generateFinancialInsights(input: {}) {
      jobId
      status
    }
  }
`;
