import React, { useState, useMemo } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Separator } from "./ui/Separator";
import { Spinner } from "./ui/Spinner";
import { GET_SPENDING_AND_INSIGHTS } from "../queries/overviewQueries";
import DateRangeSelector from "./ui/DateRangeSelector";
import SpendingBreakdownChart from "./ui/SpendingBreakdownChart";
import { COLORS, formatCurrency } from "../lib/utils";
import { motion } from "framer-motion";
import {
  Table,
  TableHeader,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
} from "./ui/Table";
import { getAmountColor } from "../lib/utils";
import RecurringInsights from "./RecurringInsights";
import PatternCard from "./PatternCard";
import { GENERATE_INSIGHTS } from "../mutations/insightMutations";
import { toast } from "react-hot-toast";
import { Button } from "./ui/Button";
import InsightsDisplay from "./InsightsDisplay";

const SpendingHome = () => {
  const [selectedPreset, setSelectedPreset] = useState("30");
  const [dateRange, setDateRange] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date(),
  });

  const {
    data: spendingData,
    loading: spendingLoading,
    error: spendingError,
    refetch,
  } = useQuery(GET_SPENDING_AND_INSIGHTS, {
    variables: {
      startDate: dateRange?.startDate?.toISOString(),
      endDate: dateRange?.endDate?.toISOString(),
    },
    skip: !dateRange?.startDate || !dateRange?.endDate,
    fetchPolicy: "network-only",
  });

  const [generateInsights, { loading: generateInsightsLoading }] = useMutation(
    GENERATE_INSIGHTS,
    {
      variables: { input: {} },
      onCompleted: () => {
        toast.success("Generating new insights...");
        setTimeout(() => {
          refetch();
        }, 5000);
      },
      onError: (error) => {
        toast.error(`Error generating insights: ${error.message}`);
      },
    }
  );

  const handleDateRangeChange = (newDateRange, preset) => {
    setDateRange(newDateRange);
    setSelectedPreset(preset);
  };

  const totalSpending =
    spendingData?.spendingBreakdown?.categoryBreakdown.reduce(
      (sum, item) => sum + Math.abs(item.amount),
      0
    ) || 0;

  const spendingInsights = useMemo(() => {
    if (!spendingData?.spendingBreakdown?.merchantBreakdown) return null;

    const merchants = spendingData.spendingBreakdown.merchantBreakdown;
    const sortedByAmount = [...merchants].sort(
      (a, b) => Math.abs(b.amount) - Math.abs(a.amount)
    );

    return {
      largestPurchase: sortedByAmount[0],
      smallestPurchase: sortedByAmount[sortedByAmount.length - 1],
      averageTransaction:
        merchants.reduce((sum, m) => sum + Math.abs(m.amount), 0) /
        merchants.length,
      totalTransactions: merchants.length,
    };
  }, [spendingData]);

  if (spendingLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <Spinner />
      </div>
    );
  }

  if (spendingError) {
    return <div>Error loading spending data: {spendingError.message}</div>;
  }

  return (
    <div className="flex flex-col h-full w-full overflow-auto">
      <div className="flex py-3">
        <div className="flex flex-col w-full">
          <div className="flex flex-col items-center px-4 pb-4">
            <div className="flex flex-row items-center justify-between w-full">
              <h2 className="text-xl font-semibold">Spending Analysis</h2>
              <div className="flex flex-row items-center gap-4">
                <DateRangeSelector
                  initialDateRange={dateRange}
                  onDateRangeChange={handleDateRangeChange}
                  selectedPreset={selectedPreset}
                  setSelectedPreset={setSelectedPreset}
                />
                <Button
                  onClick={() => generateInsights()}
                  disabled={generateInsightsLoading}
                  variant="outline"
                >
                  {generateInsightsLoading ? (
                    <>
                      <Spinner className="mr-2 h-4 w-4" />
                      Generating...
                    </>
                  ) : (
                    "Generate Insights"
                  )}
                </Button>
              </div>
            </div>
          </div>
          <Separator />
          <div className="p-4">
            <div className="grid grid-cols-1 gap-6">
              <div className="col-span-1">
                <InsightsDisplay />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <Card>
                  <CardHeader>
                    <CardTitle>Spending Chart</CardTitle>
                  </CardHeader>
                  <CardContent className="p-0">
                    <SpendingBreakdownChart
                      data={spendingData?.spendingBreakdown?.categoryBreakdown}
                    />
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader>
                    <CardTitle>Category Details</CardTitle>
                  </CardHeader>
                  <CardContent className="p-0 sm:p-6">
                    <div className="overflow-x-auto">
                      <Table>
                        <TableHeader>
                          <TableRow>
                            <TableHead className="p-2 sm:p-4 whitespace-nowrap w-1/2 sm:w-2/5">
                              Category
                            </TableHead>
                            <TableHead className="text-right p-2 sm:p-4 whitespace-nowrap w-1/4 sm:w-[30%]">
                              Amount
                            </TableHead>
                            <TableHead className="text-right p-2 sm:p-4 whitespace-nowrap w-1/4 sm:w-[30%]">
                              % of Total
                            </TableHead>
                          </TableRow>
                        </TableHeader>
                        <TableBody>
                          {[
                            ...(spendingData?.spendingBreakdown
                              ?.categoryBreakdown || []),
                          ]
                            .sort(
                              (a, b) => Math.abs(b.amount) - Math.abs(a.amount)
                            )
                            .map((category, index) => (
                              <TableRow
                                key={category.category.id}
                                className="hover:bg-gray-100 transition-colors"
                              >
                                <TableCell className="p-2 sm:p-4 whitespace-nowrap">
                                  <div className="flex items-center gap-1 sm:gap-2">
                                    <div
                                      className="h-2 w-2 sm:h-3 sm:w-3 rounded-full flex-shrink-0"
                                      style={{
                                        backgroundColor:
                                          COLORS[index % COLORS.length],
                                      }}
                                    />
                                    <span className="text-sm sm:text-base">
                                      {category.category.name}
                                    </span>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={`text-right p-2 sm:p-4 text-sm sm:text-base whitespace-nowrap ${getAmountColor(category.amount)}`}
                                >
                                  {formatCurrency(category.amount)}
                                </TableCell>
                                <TableCell className="text-right p-2 sm:p-4 text-sm sm:text-base whitespace-nowrap">
                                  {(
                                    (Math.abs(category.amount) /
                                      totalSpending) *
                                    100
                                  ).toFixed(1)}
                                  %
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <Card>
                  <CardHeader>
                    <CardTitle>Spending Summary</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      {spendingInsights ? (
                        <>
                          <div>
                            <h3 className="text-sm font-medium text-gray-500">
                              Largest Transaction
                            </h3>
                            <p className="mt-1 text-2xl font-semibold">
                              {formatCurrency(
                                Math.abs(
                                  spendingInsights.largestPurchase.amount
                                )
                              )}
                            </p>
                            <p className="text-sm text-gray-500">
                              {spendingInsights.largestPurchase.merchantName ||
                                "Unknown Merchant"}
                            </p>
                          </div>
                          <div>
                            <h3 className="text-sm font-medium text-gray-500">
                              Average Transaction
                            </h3>
                            <p className="mt-1 text-2xl font-semibold">
                              {formatCurrency(
                                spendingInsights.averageTransaction
                              )}
                            </p>
                          </div>
                        </>
                      ) : (
                        <div className="text-center text-gray-500">
                          No data available
                        </div>
                      )}
                    </div>
                  </CardContent>
                </Card>

                <Card>
                  <CardHeader>
                    <CardTitle>Top Merchants</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      {[
                        ...(spendingData?.spendingBreakdown
                          ?.merchantBreakdown || []),
                      ]
                        .sort((a, b) => Math.abs(b.amount) - Math.abs(a.amount))
                        .slice(0, 4)
                        .map((merchant) => (
                          <div
                            key={merchant.merchantName || "unknown"}
                            className="flex justify-between items-center p-3 sm:p-4 bg-gray-50 rounded-lg"
                          >
                            <span className="font-medium text-sm sm:text-base truncate mr-2">
                              {merchant.merchantName || "Unknown Merchant"}
                            </span>
                            <span className="text-gray-600 font-semibold whitespace-nowrap">
                              {formatCurrency(merchant.amount)}
                            </span>
                          </div>
                        ))}
                    </div>
                  </CardContent>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpendingHome;
