import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { AlertTriangle } from "lucide-react";
import { Badge } from "./ui/Badge";
import { formatInsightAmount } from "../lib/utils";

const getSeverityColor = (severity: string) => {
  switch (severity.toLowerCase()) {
    case "high":
      return "bg-red-100 text-red-800";
    case "medium":
      return "bg-yellow-100 text-yellow-800";
    case "low":
      return "bg-blue-100 text-blue-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};

export const RiskAlertsPanel = ({ alerts }) => {
  if (!alerts?.length) return null;

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <AlertTriangle className="h-5 w-5 text-red-500" />
          Risk Alerts
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {alerts.map((alert, index) => (
            <div key={index} className="border-b pb-4 last:border-0">
              <div className="flex justify-between items-start">
                <h4 className="font-medium">
                  {alert.type
                    .split("_")
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(" ")}
                </h4>
                <Badge className={getSeverityColor(alert.severity)}>
                  {alert.severity.charAt(0).toUpperCase() +
                    alert.severity.slice(1)}
                </Badge>
              </div>
              <p className="text-sm text-gray-600 mt-1">{alert.description}</p>
              {alert.impact && (
                <p className="text-sm font-medium text-red-600 mt-1">
                  Potential Impact: {formatInsightAmount(alert.impact)}
                </p>
              )}
              {alert.recommendations?.length > 0 && (
                <div className="mt-2">
                  <h5 className="text-sm font-medium">Recommendations:</h5>
                  <ul className="mt-1 space-y-1">
                    {alert.recommendations.map((rec, idx) => (
                      <li key={idx} className="text-sm text-gray-600">
                        • {rec}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
