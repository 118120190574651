import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Wallet } from "lucide-react";
import { Badge } from "./ui/Badge";
import { formatInsightAmount } from "../lib/utils";

const getStatusColor = (status: string) => {
  switch (status.toLowerCase()) {
    case "over":
      return "bg-red-100 text-red-800";
    case "under":
      return "bg-yellow-100 text-yellow-800";
    case "optimal":
      return "bg-green-100 text-green-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};

export const BudgetInsightsPanel = ({ insights }) => {
  if (!insights?.length) return null;

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <Wallet className="h-5 w-5 text-purple-500" />
          Budget Insights
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {insights.map((insight, index) => (
            <div key={index} className="border-b pb-4 last:border-0">
              <div className="flex justify-between items-start">
                <h4 className="font-medium">{insight.sliceName}</h4>
                <Badge className={getStatusColor(insight.status)}>
                  {insight.status}
                </Badge>
              </div>
              <div className="flex justify-between items-center mt-2">
                <span className="text-sm text-gray-600">
                  Trend: {insight.trend}
                </span>
                <span
                  className={`text-sm ${insight.amountDiff > 0 ? "text-red-600" : "text-green-600"}`}
                >
                  {formatInsightAmount(Math.abs(insight.amountDiff))}
                </span>
              </div>
              {insight.recommendations?.length > 0 && (
                <div className="mt-2">
                  <ul className="space-y-1">
                    {insight.recommendations.map((rec, idx) => (
                      <li key={idx} className="text-sm text-gray-600">
                        • {rec}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
