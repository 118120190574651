import React from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { TrendingUp } from "lucide-react";
import { Badge } from "./ui/Badge";
import { formatCurrency, formatInsightAmount } from "../lib/utils";

const getTypeColor = (type: string) => {
  switch (type.toLowerCase()) {
    case "pattern":
      return "bg-blue-100 text-blue-800";
    case "anomaly":
      return "bg-red-100 text-red-800";
    case "trend":
      return "bg-purple-100 text-purple-800";
    default:
      return "bg-gray-100 text-gray-800";
  }
};

export const SpendingInsightsPanel = ({ insights }) => {
  if (!insights?.length) return null;

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-2">
          <TrendingUp className="h-5 w-5 text-blue-500" />
          Spending Insights
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          {insights.map((insight, index) => (
            <div key={index} className="border-b pb-4 last:border-0">
              <div className="flex justify-between items-start">
                <div>
                  <Badge className={getTypeColor(insight.type)}>
                    {insight.type.charAt(0).toUpperCase() +
                      insight.type.slice(1)}
                  </Badge>
                  <span className="text-sm text-gray-500 ml-2">
                    {insight.category}
                  </span>
                </div>
                <div className="text-right">
                  <span className="text-sm text-gray-500">
                    Potential Impact:{" "}
                  </span>
                  <span className="text-gray-600 font-medium">
                    {formatInsightAmount(insight.impact)}
                  </span>
                </div>
              </div>
              <p className="text-sm text-gray-600 mt-2">
                {insight.description}
              </p>
              {insight.recommendations?.length > 0 && (
                <div className="mt-2">
                  <ul className="space-y-1">
                    {insight.recommendations.map((rec, idx) => (
                      <li key={idx} className="text-sm text-gray-600">
                        • {rec}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  );
};
