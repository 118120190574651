import { gql } from '@apollo/client';

export const GET_FINANCIAL_INSIGHTS = gql`
  query GetFinancialInsights($limit: Int, $startDate: ISO8601DateTime, $endDate: ISO8601DateTime) {
    financialInsights(limit: $limit, startDate: $startDate, endDate: $endDate) {
      id
      generatedAt
      confidenceScore
      spendingInsights {
        type
        category
        description
        impact
        confidence
        recommendations
      }
      budgetInsights {
        sliceName
        status
        amountDiff
        trend
        recommendations
      }
      savingsInsights {
        type
        description
        potentialImpact
        timeframe
        steps
        goal
      }
      riskAlerts {
        type
        severity
        description
        impact
        recommendations
      }
      optimizationSuggestions {
        type
        description
        currentCost
        potentialSavings
        implementationSteps
      }
    }
  }
`;
