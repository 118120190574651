import React, { useCallback, useEffect, useState } from "react";
import { Button } from "./ui/Button";
import { useMutation, useQuery } from "@apollo/client";
import {
  SYNC_PLAID_ACCOUNTS,
  SYNC_PLAID_TRANSACTIONS,
  REMOVE_PLAID_ACCOUNT,
  UPDATE_ACCOUNT_NAME,
  TRIGGER_PLAID_SYNC,
} from "../mutations/plaidMutations";
import { useUser } from "../lib/UserContext";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { Separator } from "./ui/Separator";
import {
  GET_ACCOUNTS_FOR_USER,
  GET_USER_PLAID_STATUS,
  GET_INSTITUTIONS,
  CHECK_PLAID_SYNC_STATUS,
} from "../queries/plaidQueries";
import toast from "react-hot-toast";
import ChangePasswordModal from "./ChangePasswordModal";
import DeleteAccountModal from "./DeleteAccountModal";
import PlaidLink from "./PlaidLink";
import RemoveAccountModal from "./RemoveAccountModal";
import {
  Accordion,
  AccordionItem,
  AccordionTrigger,
  AccordionContent,
} from "./ui/Accordion";
import { Edit2 } from "lucide-react";
import EditAccountNameModal from "./EditAccountNameModal";
import InstitutionLogo from "./InstitutionLogo";
import { Spinner } from "./ui/Spinner";
import { useSafeToSpend } from "./SafeToSpend";

const SettingsHome = () => {
  const { user } = useUser();
  const { refetchBreakdown } = useSafeToSpend();
  const [changePasswordModalOpen, setChangePasswordModalOpen] = useState(false);
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const [needPlaidReconnection, setNeedPlaidReconnection] = useState(false);
  const [plaidModalOpen, setPlaidModalOpen] = useState(false);
  const [removeAccountModalOpen, setRemoveAccountModalOpen] = useState(false);
  const [accountToRemove, setAccountToRemove] = useState("");
  const [editingAccountId, setEditingAccountId] = useState(null);
  const [editedAccountName, setEditedAccountName] = useState("");
  const [editAccountModalOpen, setEditAccountModalOpen] = useState(false);
  const [removePlaidItemModalOpen, setRemovePlaidItemModalOpen] =
    useState(false);
  const [plaidItemToRemove, setPlaidItemToRemove] = useState("");
  const [isRefetching, setIsRefetching] = useState(false);
  const [removeItemType, setRemoveItemType] = useState("account");
  const [removeItemName, setRemoveItemName] = useState("");
  const [isRemoving, setIsRemoving] = useState(false);
  const [syncJobId, setSyncJobId] = useState(null);
  const [syncStatus, setSyncStatus] = useState("idle");
  const [isSyncing, setIsSyncing] = useState(false);

  const [syncAccounts, { loading: syncAccountsLoading }] = useMutation(
    SYNC_PLAID_ACCOUNTS,
    {
      onCompleted: () => {
        toast.success("Accounts synced");
      },
      onError: (error) => {
        toast.error(error.message);
      },
    }
  );
  const [triggerPlaidSync, { loading: syncTransactionsLoading }] = useMutation(
    TRIGGER_PLAID_SYNC,
    {
      onCompleted: (data) => {
        if (data.triggerPlaidSync.jobId) {
          setSyncJobId(data.triggerPlaidSync.jobId);
          setSyncStatus("in_progress");
          toast.success("Sync started. This may take a few minutes.");
          refetchSyncStatus();
        } else if (data.triggerPlaidSync.error) {
          toast.error(data.triggerPlaidSync.error);
        }
      },
      onError: (error) => {
        toast.error("Failed to start sync: " + error.message);
      },
    }
  );
  const [removePlaidAccount] = useMutation(REMOVE_PLAID_ACCOUNT, {
    onCompleted: () => {
      toast.success(
        `${removeItemType === "institution" ? "Institution" : "Account"} removed`
      );
      refetchAccounts();
      setRemoveAccountModalOpen(false);
      setRemovePlaidItemModalOpen(false);
      refetchBreakdown();
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const [updateAccountName] = useMutation(UPDATE_ACCOUNT_NAME, {
    onCompleted: () => {
      toast.success("Account name updated");
      refetchAccounts();
      setEditingAccountId(null);
      setEditedAccountName("");
    },
    onError: (error) => {
      toast.error(error.message);
    },
  });

  const {
    data: accountsData,
    loading: accountsLoading,
    refetch: refetchAccounts,
  } = useQuery(GET_ACCOUNTS_FOR_USER, {
    fetchPolicy: "network-only",
  });

  const { data: plaidStatusData, refetch: refetchPlaidStatus } = useQuery(
    GET_USER_PLAID_STATUS
  );

  const { data: institutionsData, refetch: refetchInstitutions } =
    useQuery(GET_INSTITUTIONS);

  const { data: syncStatusData, refetch: refetchSyncStatus } = useQuery(
    CHECK_PLAID_SYNC_STATUS,
    {
      variables: { jobId: syncJobId },
      pollInterval: syncStatus === "in_progress" ? 2000 : 0,
      skip: !syncJobId || syncStatus === "completed",
      onCompleted: (data) => {
        if (data.plaidSyncStatus === "completed") {
          setSyncStatus("completed");
          setSyncJobId(null);
          refetchAccountsAndInstitutions();
        }
      },
    }
  );

  useEffect(() => {
    if (plaidStatusData) {
      const { hasPlaidDetails, needPlaidReconnection } = plaidStatusData.user;
      if (!hasPlaidDetails) {
        setPlaidModalOpen(true);
      }
      if (needPlaidReconnection) {
        setNeedPlaidReconnection(true);
      }
    }
  }, [plaidStatusData]);

  useEffect(() => {
    if (syncStatusData) {
      setSyncStatus(syncStatusData.plaidSyncStatus);
      if (syncStatusData.plaidSyncStatus === "completed") {
        setIsRefetching(true);
        refetchAccounts();
        refetchInstitutions();
        refetchPlaidStatus();
        refetchBreakdown();
        setSyncJobId(null);
        setIsSyncing(false);
        toast.success("Sync completed successfully.");
        setIsRefetching(false);
      } else if (syncStatusData.plaidSyncStatus === "error") {
        toast.error("An error occurred during sync. Please try again.");
        setSyncJobId(null);
        setIsSyncing(false);
      }
    }
  }, [
    syncStatusData,
    refetchAccounts,
    refetchInstitutions,
    refetchPlaidStatus,
  ]);

  const refetchAccountsAndInstitutions = useCallback(async () => {
    setIsRefetching(true);
    await refetchAccounts();
    await refetchInstitutions();
    setIsRefetching(false);
  }, [refetchAccounts, refetchInstitutions]);

  const handleRemoveAccount = (account) => {
    setAccountToRemove(account.id);
    setRemoveItemType("account");
    setRemoveItemName(account.name || "Unnamed Account");
    setRemoveAccountModalOpen(true);
  };

  const handleRemovePlaidItem = (institution) => {
    if (!institution.id) {
      console.error("Cannot remove institution: institutionId is null");
      return;
    }
    const plaidItem = accountsData?.accountsForUser?.find(
      (account) => account.plaidItem?.institutionId === institution.id
    )?.plaidItem;
    if (plaidItem) {
      setPlaidItemToRemove(plaidItem.id);
      setRemoveItemType("institution");
      setRemoveItemName(institution.name);
      setRemoveAccountModalOpen(true);
    } else {
      console.error("No Plaid Item found for this institution");
    }
  };

  const confirmRemoveItem = async () => {
    setIsRemoving(true);
    try {
      if (removeItemType === "account") {
        await removePlaidAccount({ variables: { accountId: accountToRemove } });
      } else if (removeItemType === "institution") {
        await removePlaidAccount({
          variables: { plaidItemId: plaidItemToRemove },
        });
      }
      setRemoveAccountModalOpen(false);
      await refetchAccountsAndInstitutions();
    } catch (error) {
      console.error("Error removing item:", error);
      toast.error("Failed to remove item");
    } finally {
      setIsRemoving(false);
    }
  };

  const handleEditAccount = (account) => {
    setEditingAccountId(account.id);
    setEditedAccountName(account.name);
    setEditAccountModalOpen(true);
  };

  const handleSaveAccountName = async () => {
    try {
      await updateAccountName({
        variables: { accountId: editingAccountId, name: editedAccountName },
      });
      setEditAccountModalOpen(false);
      setEditingAccountId(null);
      setEditedAccountName("");
      refetchAccounts();
    } catch (error) {
      console.error("Error updating account name:", error);
      toast.error("Failed to update account name");
    }
  };

  return (
    <div className="flex-1 flex-col h-full w-full overflow-auto">
      <div className="flex py-6 items-center justify-between space-y-2">
        <div className="flex flex-col w-full px-4 pb-2">
          <h2 className="text-xl font-semibold mb-4">Settings</h2>
          <Separator />

          <Card className="mb-6 mt-4">
            <CardHeader>
              <CardTitle>Sync Data</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex items-center space-x-4">
                <Button
                  variant="default"
                  onClick={() => syncAccounts()}
                  disabled={syncAccountsLoading}
                >
                  {syncAccountsLoading ? "Syncing..." : "Sync Accounts"}
                </Button>
                <Button
                  variant="default"
                  onClick={() => {
                    setIsSyncing(true);
                    triggerPlaidSync({ variables: { force: true } });
                  }}
                  disabled={syncTransactionsLoading}
                >
                  {syncTransactionsLoading ? "Syncing..." : "Sync Transactions"}
                </Button>
              </div>
            </CardContent>
          </Card>

          <Card className="mb-6">
            <CardHeader>
              <CardTitle>Linked Institutions and Accounts</CardTitle>
            </CardHeader>
            <CardContent>
              {accountsLoading || !institutionsData || isRefetching ? (
                <Spinner />
              ) : (!institutionsData.institutions ||
                  institutionsData.institutions.length === 0) &&
                (!accountsData?.accountsForUser ||
                  accountsData.accountsForUser.length === 0) ? (
                <p>
                  No linked institutions or accounts. Click the button below to
                  link an account.
                </p>
              ) : (
                <Accordion
                  type="single"
                  collapsible
                  className="w-full space-y-4"
                >
                  {institutionsData.institutions &&
                    institutionsData.institutions.map((institution) => {
                      const institutionAccounts =
                        accountsData?.accountsForUser?.filter(
                          (account) =>
                            account.plaidItem?.institutionId === institution.id
                        ) || [];
                      return (
                        <AccordionItem
                          value={institution.id}
                          key={institution.id}
                          className="border rounded-lg px-4 hover:bg-gray-50/50 transition-all"
                        >
                          <AccordionTrigger className="py-4 hover:no-underline">
                            <div className="flex items-center justify-between w-full">
                              <div className="flex items-center">
                                <InstitutionLogo
                                  logo={institution.logo}
                                  name={institution.name}
                                  className="w-8 h-8 mr-2"
                                />
                                <span className="font-medium">
                                  {institution.name}
                                </span>
                              </div>
                              {institutionAccounts.some(
                                (account) =>
                                  account.plaidItem?.needPlaidReconnection
                              ) && (
                                <span className="text-sm text-red-500 font-medium ml-2">
                                  Needs Reconnection
                                </span>
                              )}
                            </div>
                          </AccordionTrigger>
                          <AccordionContent className="pb-4 pt-2 space-y-4">
                            {institutionAccounts.map((account) => (
                              <div
                                key={account.id}
                                className="p-3 rounded-md hover:bg-gray-100/50 transition-all flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3"
                              >
                                <div className="flex items-center">
                                  <span className="break-all">
                                    {`${account.name} (${account.mask})` ||
                                      `${account.mask} - ${institution.name}`}
                                  </span>
                                </div>
                                <div className="flex items-center gap-2 w-full sm:w-auto">
                                  <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleEditAccount(account)}
                                    className="flex-1 sm:flex-none flex items-center"
                                  >
                                    <Edit2 className="h-4 w-4 mr-2" />
                                    Edit
                                  </Button>
                                  <Button
                                    variant="destructive"
                                    size="sm"
                                    onClick={() => handleRemoveAccount(account)}
                                    className="flex-1 sm:flex-none flex items-center"
                                  >
                                    Remove Account
                                  </Button>
                                </div>
                              </div>
                            ))}
                            {institutionAccounts.length > 0 && (
                              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-3 mt-4 p-3">
                                <Button
                                  variant="destructive"
                                  size="sm"
                                  onClick={() =>
                                    handleRemovePlaidItem(institution)
                                  }
                                  className="w-full sm:w-auto"
                                >
                                  Remove Institution
                                </Button>
                                {institutionAccounts.some(
                                  (account) =>
                                    account.plaidItem?.needPlaidReconnection
                                ) && (
                                  <PlaidLink
                                    setModalOpen={setPlaidModalOpen}
                                    needPlaidReconnection={true}
                                    onSyncComplete={(jobId) => {
                                      setSyncJobId(jobId);
                                      setSyncStatus("in_progress");
                                      setIsSyncing(true);
                                    }}
                                    refetchPlaidStatus={refetchPlaidStatus}
                                    plaidItemId={
                                      institutionAccounts[0].plaidItem.id
                                    }
                                  />
                                )}
                              </div>
                            )}
                          </AccordionContent>
                        </AccordionItem>
                      );
                    })}

                  {/* Unknown Institution section */}
                  {accountsData?.accountsForUser &&
                    accountsData.accountsForUser.some(
                      (account) => !account.plaidItem
                    ) && (
                      <AccordionItem value="unknown">
                        <AccordionTrigger>Unknown Institution</AccordionTrigger>
                        <AccordionContent>
                          {accountsData.accountsForUser
                            .filter((account) => !account.plaidItem)
                            .map((account) => (
                              <div
                                key={account.id}
                                className="py-2 flex justify-between items-center"
                              >
                                <span>{account.name || "Unnamed Account"}</span>
                                <div>
                                  <Button
                                    variant="outline"
                                    size="sm"
                                    onClick={() => handleEditAccount(account)}
                                  >
                                    <Edit2 className="h-4 w-4 mr-2" />
                                    Edit
                                  </Button>
                                  <Button
                                    variant="destructive"
                                    size="sm"
                                    onClick={() => handleRemoveAccount(account)}
                                    className="ml-2"
                                  >
                                    Remove Account
                                  </Button>
                                </div>
                              </div>
                            ))}
                        </AccordionContent>
                      </AccordionItem>
                    )}
                </Accordion>
              )}
            </CardContent>
          </Card>

          <Card className="mb-6">
            <CardHeader>
              <CardTitle>Account Management</CardTitle>
            </CardHeader>
            <CardContent>
              <Button onClick={() => setChangePasswordModalOpen(true)}>
                Change Password
              </Button>
              <Button
                variant="destructive"
                className="ml-4"
                onClick={() => setDeleteAccountModalOpen(true)}
              >
                Delete Account
              </Button>
            </CardContent>
          </Card>
        </div>
      </div>

      <ChangePasswordModal
        isOpen={changePasswordModalOpen}
        onClose={() => setChangePasswordModalOpen(false)}
      />
      <DeleteAccountModal
        isOpen={deleteAccountModalOpen}
        onClose={() => setDeleteAccountModalOpen(false)}
      />
      {removeAccountModalOpen && (
        <RemoveAccountModal
          isOpen={removeAccountModalOpen}
          onClose={() => setRemoveAccountModalOpen(false)}
          onConfirm={confirmRemoveItem}
          name={removeItemName}
          type={removeItemType}
          isRemoving={isRemoving}
        />
      )}
      {editAccountModalOpen && (
        <EditAccountNameModal
          isOpen={editAccountModalOpen}
          onClose={() => setEditAccountModalOpen(false)}
          onConfirm={handleSaveAccountName}
          accountName={editedAccountName}
          setAccountName={setEditedAccountName}
        />
      )}
      {(isSyncing || isRefetching) && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg flex flex-col items-center">
            <Spinner className="w-8 h-8" />
            <p className="mt-2 text-sm text-gray-500">
              {isSyncing ? "Syncing with your bank..." : "Refreshing data..."}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default SettingsHome;
