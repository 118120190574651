import React from "react";
import { useQuery } from "@apollo/client";
import { format } from "date-fns";
import { GET_FINANCIAL_INSIGHTS } from "../queries/financialInsightsQueries";
import { RiskAlertsPanel } from "./RiskAlertPanel";
import { SavingsInsightsPanel } from "./SavingInsightsPanel";
import { OptimizationSuggestionsPanel } from "./OptimizationSuggestionsPanel";
import { SpendingInsightsPanel } from "./SpendingInsightsPanel";
import { Spinner } from "./ui/Spinner";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/Card";
import { BrainCircuit } from "lucide-react";
import { BudgetInsightsPanel } from "./BudgetInsightsPanel";

export default function InsightsDisplay() {
  const { data, loading, error } = useQuery(GET_FINANCIAL_INSIGHTS, {
    variables: { limit: 1 },
  });

  if (loading) return <Spinner />;
  if (error) return <div>Error loading insights</div>;

  // Handle empty state
  if (!data?.financialInsights?.length) {
    return (
      <Card>
        <CardHeader>
          <CardTitle className="flex items-center gap-2">
            <BrainCircuit className="h-5 w-5 text-blue-500" />
            AI Financial Insights
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-center py-8">
            <p className="text-gray-500">
              No insights available yet. Generate insights to see AI-powered
              analysis of your spending.
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  const insight = data.financialInsights[0];

  return (
    <div className="w-full space-y-6">
      <div className="flex justify-between items-start">
        <h2 className="text-2xl font-semibold">Financial Insights</h2>
        <div className="text-sm text-gray-500 text-right space-y-1">
          <div>Generated {format(new Date(insight.generatedAt), "PPP")}</div>
          <div>
            Confidence Score: {(insight.confidenceScore * 100).toFixed(1)}%
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-6">
        <SpendingInsightsPanel insights={insight.spendingInsights} />
        <BudgetInsightsPanel insights={insight.budgetInsights} />
        <RiskAlertsPanel alerts={insight.riskAlerts} />
        <SavingsInsightsPanel insights={insight.savingsInsights} />
        <OptimizationSuggestionsPanel
          suggestions={insight.optimizationSuggestions}
        />
      </div>
    </div>
  );
}
