import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PlaidLink from "./PlaidLink";
import PropTypes from "prop-types";
import { AlertCircleIcon } from "lucide-react";
import { Button } from "./ui/Button";
import { Separator } from "./ui/Separator";

const PlaidModal = ({
  modalOpen,
  setModalOpen,
  needPlaidReconnection,
  setNeedPlaidReconnection,
  refetchPlaidStatus,
  onSyncStart,
  onSyncComplete,
}) => {
  return (
    <Transition.Root show={modalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <Dialog.Title
                    as="h3"
                    className="flex items-center gap-2 text-base font-semibold leading-6 text-gray-900"
                  >
                    <AlertCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                    {!needPlaidReconnection
                      ? "Connect Bank Account"
                      : "Reconnect Bank Account"}
                  </Dialog.Title>
                  <Separator orientation="horizontal" className="w-full mt-4" />
                  <div className="mt-4">
                    {needPlaidReconnection ? (
                      <p className="text-sm text-gray-500">
                        To continue using Cake Budget, we need to reconnect your
                        bank account via Plaid to get the latest data.
                      </p>
                    ) : (
                      <p className="text-sm text-gray-500">
                        To use Cake Budget, we use Plaid to pull your
                        transaction data from your bank. We do not and will
                        never sell your data.
                      </p>
                    )}
                  </div>
                </div>
                <Separator orientation="horizontal" className="w-full mt-4" />
                <div className="flex flex-row justify-between items-center mt-4">
                  <Button
                    variant="ghost"
                    onClick={() => {
                      setModalOpen(false);
                      setNeedPlaidReconnection(false);
                    }}
                  >
                    Cancel
                  </Button>
                  <PlaidLink
                    setModalOpen={setModalOpen}
                    needPlaidReconnection={needPlaidReconnection}
                    refetchPlaidStatus={refetchPlaidStatus}
                    onSyncStart={onSyncStart}
                    onSyncComplete={(jobId) => {
                      if (onSyncComplete) {
                        onSyncComplete(jobId);
                      }
                    }}
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

PlaidModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  needPlaidReconnection: PropTypes.bool.isRequired,
  setNeedPlaidReconnection: PropTypes.func.isRequired,
  refetchPlaidStatus: PropTypes.func,
  onSyncStart: PropTypes.func,
  onSyncComplete: PropTypes.func,
};

export default PlaidModal;
