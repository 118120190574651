import React, { useCallback, useEffect, useState } from "react";
import { usePlaidLink } from "react-plaid-link";
import { useMutation } from "@apollo/client";
import {
  EXCHANGE_PUBLIC_TOKEN,
  CREATE_PLAID_LINK_TOKEN,
  TRIGGER_PLAID_SYNC,
} from "../mutations/plaidMutations";
import { Button } from "./ui/Button";
import PropTypes from "prop-types";
import { Spinner } from "./ui/Spinner";
import { toast } from "react-hot-toast";

const PlaidLink = ({
  needPlaidReconnection,
  setModalOpen,
  onSyncComplete,
  refetchPlaidStatus,
  plaidItemId,
  onSyncStart,
}) => {
  const [createPlaidLinkToken, { data: linkTokenData }] = useMutation(
    CREATE_PLAID_LINK_TOKEN
  );
  const [exchangePublicToken, { loading: loadingExchangePublicToken }] =
    useMutation(EXCHANGE_PUBLIC_TOKEN);
  const [triggerPlaidSync, { loading: loadingTriggerPlaidSync }] =
    useMutation(TRIGGER_PLAID_SYNC);
  const [isSyncing, setIsSyncing] = useState(false);

  const onSuccess = useCallback(
    async (public_token, metadata) => {
      console.log("onSuccess");
      console.log(metadata);
      console.log(public_token);
      setIsSyncing(true);
      if (onSyncStart) onSyncStart();

      try {
        const response = await exchangePublicToken({
          variables: {
            publicToken: public_token,
            itemId: needPlaidReconnection ? metadata.account_id : null,
          },
        });

        if (!response.data.exchangePublicToken.success) {
          toast.error(response.data.exchangePublicToken.errors[0]);
          setModalOpen(false);
          setIsSyncing(false);
          return;
        }

        const { data: triggerPlaidSyncData } = await triggerPlaidSync({
          variables: { force: true },
        });

        setIsSyncing(false);
        setModalOpen(false);

        if (onSyncComplete) {
          onSyncComplete(triggerPlaidSyncData?.triggerPlaidSync?.jobId);
        }

        await refetchPlaidStatus();
      } catch (error) {
        console.error("Error in plaid onSuccess:", error);
        toast.error(
          error.message?.includes("institution already exists")
            ? "You've already connected this institution"
            : "Failed to connect bank account"
        );
        setIsSyncing(false);
      }
    },
    [
      exchangePublicToken,
      setModalOpen,
      needPlaidReconnection,
      onSyncComplete,
      refetchPlaidStatus,
      triggerPlaidSync,
      onSyncStart,
    ]
  );

  const onExit = useCallback(
    (error, metadata) => {
      if (error) {
        console.error("Plaid Link error:", error);

        if (error.error_code === "INVALID_LINK_TOKEN") {
          createPlaidLinkToken({
            variables: {
              plaidItemId: needPlaidReconnection ? plaidItemId : null,
            },
          });
        } else if (
          error.error_message?.includes("institution already exists")
        ) {
          toast.error("You've already connected this institution");
        } else {
          toast.error("An error occurred while connecting to your bank");
        }
      }

      if (metadata?.status === "requires_credentials") {
        toast.error("Please update your bank credentials");
      }
    },
    [createPlaidLinkToken, needPlaidReconnection, plaidItemId]
  );

  useEffect(() => {
    createPlaidLinkToken({
      variables: { plaidItemId: needPlaidReconnection ? plaidItemId : null },
    });
  }, [createPlaidLinkToken, plaidItemId, needPlaidReconnection]);

  let isOauth = false;
  const config = {
    token: linkTokenData?.createPlaidLinkToken?.linkToken,
    onSuccess,
    onExit,
    onEvent: (eventName, metadata) => {
      console.log(`Plaid Event: ${eventName}`, metadata);
    },
  };

  if (window.location.href.includes("?oauth_state_id=")) {
    isOauth = true;
    config.receivedRedirectUri = window.location.href;
  }

  const { open, ready } = usePlaidLink(config);

  useEffect(() => {
    if (isOauth && ready && linkTokenData?.createPlaidLinkToken?.linkToken) {
      open();
    }
  }, [ready, open, isOauth, linkTokenData]);

  if (isSyncing || loadingExchangePublicToken || loadingTriggerPlaidSync) {
    return (
      <div className="flex flex-col items-center">
        <Spinner />
        <p className="mt-2 text-center font-semibold">
          {loadingExchangePublicToken && "Connecting to Plaid..."}
          {loadingTriggerPlaidSync && "Syncing data..."}
          {isSyncing && !loadingExchangePublicToken && "Syncing data..."}
        </p>
      </div>
    );
  }

  return (
    <Button variant="default" onClick={() => open()} disabled={!ready}>
      {needPlaidReconnection ? "Reconnect" : "Connect Bank Account"} with Plaid
    </Button>
  );
};

PlaidLink.propTypes = {
  needPlaidReconnection: PropTypes.bool,
  setModalOpen: PropTypes.func.isRequired,
  onSyncComplete: PropTypes.func,
  refetchPlaidStatus: PropTypes.func,
  plaidItemId: PropTypes.string,
  onSyncStart: PropTypes.func,
};

export default PlaidLink;
